import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import React, {useEffect, useState} from "react";
import { BASE_URL } from "../../assets/js/_variables";

// Componentes
import Layout from "../../components/Layout";
import Slider from "../../components/Slider";

const Night = () => {
  const [lang, setLang] = useState()
  
  const txt = {
    en: {
      pageTitle: 'Rhythms of Night | HB Privileged',
      title: 'RHYTHMS OF THE NIGHT',
      subtitle: 'TOUR OVERVIEW',
      text: "Enjoy Vallarta's #1 dinner show! Board a modern catamaran from the marina to Las Caletas beach, which is only accessible by boat. Enjoy the view of the entire hotel zone of the region from the sea, while admiring one of the best sunsets that Mexico has to offer. Upon your arrival at this magical cove, you will find countless characters who, with live music, will accompany you to the theater where you will enjoy a show of fire and acrobatics similar to Cirque du Soleil. At the end of the show, enjoy a wonderful buffet dinner by candlelight and the stars, by the sea on the sand of this enchanting beach. Accompany your dinner with a fantastic open bar and have fun on your way back to Vallarta aboard the catamarans that will also delight you with a fun dance show for all audiences. This tour lasts approximately 5 hours and is definitely listed as the #1 activity to do in our destination. Book early because spaces are limited. Reserve now!",
      frequency: 'Frequency:',
      hours: 'Hours:',
      duration: 'Duration:',
      btn: 'Book now',
    },
    es: {
      pageTitle: 'Ritmos de la Noche | HB Privileged',
      title: 'RITMOS DE LA NOCHE',
      subtitle: 'RESUMEN DEL TOUR',
      text: 'Disfruta de la cena show #1 de Vallarta! Aborda un moderno catamaran desde la marina con destino a la playa de Las Caletas, la cual es solo accesible en una embarcación. Disfruta la vista de toda la zona hotelera de la región desde el mar, al tiempo que admiras uno de los mejores atardeceres que México tiene para ofrecerte. A tu llegada a esta mágica ensenada encontrarás un sinnúmero de personajes que con música en vivo te acompañarán hacia el teatro donde disfrutarás de un espectáculo de fuego y acrobacias similar al Cirque du Soleil. Al término del show, disfruta de una maravillosa cena tipo buffet a la luz de las velas y las estrellas, a un costado del mar sobre la arena, de esta encantadora playa. Acompaña tu cena de una fantástica barra libre y diviértete de regreso a Vallarta a bordo de los catamaranes que también te deleitarán con un show divertido de baile para todo público. Este tour tiene una duración de 5 horas aproximadamente y definitivamente se cataloga como la actividad #1 para realizar en nuestro destino. Reserva con anticipación porque los espacios son limitados. Reserva ya!',
      frequency: 'Frecuencia:',
      hours: 'Horas:',
      duration: 'Duración:',
      btn: 'Reserva este tour',
    }
  }

  const images = [
    `${BASE_URL}/tours/rn1.jpg`,
    `${BASE_URL}/tours/rn2.webp`,
  ]

  const getLang = () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem('lang')
    }
  }

  useEffect(() => {
    if(getLang() === 'es') {
      setLang('es')
    } else {
      setLang('en')
    }
  }, [])

  return (
    <Layout pageTitle={ lang === 'es' ? txt.es.pageTitle : txt.en.pageTitle }>
      <div>
        {/*</div><div className="h-80 overflow-hidden">*/}
        <div className="h-70 overflow-hidden">
          <img src={`${BASE_URL}/banners/ritmos.jpg`} className="w-full object-cover" alt="" />
        </div>
        <div className="section">
          <h2 className="text-2xl md:text-4xl font-bold mb-2">
            { lang === 'es' ? txt.es.title : txt.en.title }
          </h2>
          <div className="mb-4">
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
            <FontAwesomeIcon className="text-amber-400" icon={faStar} />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-xl md:text-2xl font-semibold mb-4">
                { lang === 'es' ? txt.es.subtitle : txt.en.subtitle }
              </h3>
              <p className="normal-text text-justify mb-4">
                { lang === 'es' ? txt.es.text : txt.en.text }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.frequency : txt.en.frequency }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.hours : txt.en.hours }
              </p>
              <p className="normal-text">
                { lang === 'es' ? txt.es.duration : txt.en.duration }
              </p>
            </div>

            <div className="">
              <div className="rounded overflow-hidden">
                <Slider images={images} />
              </div>
              
              <div className="text-center py-8">
                <Link
                  className="text-center w-1/2 mt-16 text-xl font-bold bg-sky-800 text-neutral-50 py-2 px-4 rounded-full" 
                  to={`${BASE_URL}/contacto`}
                >
                  { lang === 'es' ? txt.es.btn : txt.en.btn }
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
}
 
export default Night;